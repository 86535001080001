<template>
  <v-card flat class="formulario-cancelamento">
    <div class="title">
      <span>{{ userInfo.name }}, lamentamos o cancelamento. </span>
    </div>

    <div class="conteudo-formulario">
      <div class="vantagens">
        <div class="title-vantagens">
          <span>Tem certeza? Vantagens que voce irá perder:</span>
        </div>

        <ul>
          <li
            class="item"
            v-for="dataPlan in planInfo.data"
            :key="dataPlan.title"
          >
            <img
              src="@/assets/icons/check_icon.svg"
              alt=""
              :style="{ filter: planInfo.colorFiltered }"
            />
            <span>{{ dataPlan }}</span>
          </li>
        </ul>
      </div>
      <div class="detalhes">
        <div class="card-detalhes">
          <div class="title-card">
            <span>Detalhes do seu plano atual</span>
          </div>

          <div class="descricoes-card">
            <div class="plano">
              <span class="strong">Plano</span> Kristal<span
                class="strong-2"
                :style="{ color: planInfo.color }"
              >
                {{ planInfo.title }}
              </span>
            </div>
            <div class="empresa">
              <span class="strong">Kristal Connect</span>
            </div>
            <div class="valor">
              <span class="valor-pagamento strong"> {{ planInfo.preco }}</span>
              <span class="tipo-pagamento"
                >Pagamento à vista - feito com cartão de crédito</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="botoes">
      <div class="btn continue-btn" @click="goToCarteira">
        <span>Continuar com o plano</span>
      </div>
      <v-btn
        class="btn"
        :loading="loading"
        rounded
        color="primary"
        @click="cancelarPlano"
        >Cancelar plano</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import ApiService from "../../../../services/ApiService";
export default {
  props: {
    planInfo: { type: Object, default: undefined },
    userInfo: { type: Object, default: undefined },
  },
  data() {
    return {
      loading: false,
      apiService: new ApiService(),
    };
  },
  methods: {
    goToCarteira() {
      this.$router.push({ name: "carteira" });
    },
    async cancelarPlano() {
      try {
        this.loading = true
        let req = await this.apiService.post("checkout/cancelPlan", {
          Id: this.userInfo.id,
        });

        if (req.statusCode == 200) {
          this.loading = false
          this.$router.push({ name: "cancelarPlanoConfirmacao", params:{title: this.planInfo.title, preco: this.planInfo.preco} });
        }
      } catch (error) {
        this.loading = false
        this.$toast.error("Ops, tivemos um problema para cancelar o seu plano");
      }
    },
  },
};
</script>

<style  scoped>
.formulario-cancelamento {
  border: 1px solid #dadada;
  border-radius: 7px;
  padding: 2rem;
}
.title {
  text-align: center;
  font-weight: 700;
}

.conteudo-formulario {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
}
.conteudo-formulario > div {
  flex: 1 1 400px;
}
.vantagens .title-vantagens {
  font-size: 0.9em;
  font-weight: 500;
  margin: 1rem 0;
}
.vantagens ul {
  padding: 0;
  margin: 0;
}
.vantagens ul li {
  list-style: none;
}
.vantagens .item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
}
.vantagens .item img {
  width: 20px;
}
.vantagens .item span {
  font-weight: 300;
}
.card-detalhes {
  padding: 1rem;
  background: #e4e4e4;
  border: 1px solid #dadada;
  border-radius: 5px;
  width: 90%;
  margin: 1.5rem auto;
}
.card-detalhes > div {
  margin: 1rem 0;
}
.descricoes-card > div {
  margin: 1rem 0;
}
.title-card span {
  color: #000000;
  font-weight: bold;
  font-size: 1.2em;
}
.empresa span {
  font-size: 0.8em;
}
.valor span {
  display: block;
}
.valor .valor-pagamento {
  font-size: 1.4em;
}
.valor .tipo-pagamento {
  font-size: 0.8em;
  font-weight: 600;
  color: #b9b9b9;
}

.strong {
  font-weight: 600;
  color: black;
}
.strong-2 {
  font-weight: 500;
}

.botoes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  transition: 0.2s ease-in-out;
}
.btn {
  padding: 0.3rem 0.8rem;
  border-radius: 25px;
  margin: 0.4rem;
  width: 230px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.continue-btn {
  border: 1px solid var(--principal-azul);
  color: var(--principal-azul);
  cursor: pointer;
}

@media screen and (max-width: 615px) {
  .botoes {
    justify-content: center;
  }
}
</style>