<template>
  <div class="cancelamento">
    <CardCancelar class="item" :userInfo="user" :planInfo="planInfo" />
    <FormularioCancelamento class="item" :userInfo="user" :planInfo="planInfo" />
  </div>
</template>

<script>
import TiposPlanos from '../../../../services/TiposPlanos'
import CardCancelar from "../components/CardCancelarPlano.vue";
import FormularioCancelamento from "../components/FormularioCancelamento.vue";
export default {
  components: {
    CardCancelar,
    FormularioCancelamento,
  },
  data(){
      return{
          user: undefined,
          planInfo: undefined,
          tiposPlanos: new TiposPlanos()
      }
  },
  created() {
    this.user = this.$store.getters.$userLogged;
    this.planInfo = this.tiposPlanos.SelecionarPlano(this.user.planType)
  },
};
</script>

<style  scoped>
.item {
  margin: 1.5rem 0;
}
</style>
