var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cancelamento" },
    [
      _c("CardCancelar", {
        staticClass: "item",
        attrs: { userInfo: _vm.user, planInfo: _vm.planInfo },
      }),
      _c("FormularioCancelamento", {
        staticClass: "item",
        attrs: { userInfo: _vm.user, planInfo: _vm.planInfo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }