var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "formulario-cancelamento", attrs: { flat: "" } },
    [
      _c("div", { staticClass: "title" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.userInfo.name) + ", lamentamos o cancelamento. "),
        ]),
      ]),
      _c("div", { staticClass: "conteudo-formulario" }, [
        _c("div", { staticClass: "vantagens" }, [
          _c("div", { staticClass: "title-vantagens" }, [
            _c("span", [_vm._v("Tem certeza? Vantagens que voce irá perder:")]),
          ]),
          _c(
            "ul",
            _vm._l(_vm.planInfo.data, function (dataPlan) {
              return _c("li", { key: dataPlan.title, staticClass: "item" }, [
                _c("img", {
                  style: { filter: _vm.planInfo.colorFiltered },
                  attrs: {
                    src: require("@/assets/icons/check_icon.svg"),
                    alt: "",
                  },
                }),
                _c("span", [_vm._v(_vm._s(dataPlan))]),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "detalhes" }, [
          _c("div", { staticClass: "card-detalhes" }, [
            _c("div", { staticClass: "title-card" }, [
              _c("span", [_vm._v("Detalhes do seu plano atual")]),
            ]),
            _c("div", { staticClass: "descricoes-card" }, [
              _c("div", { staticClass: "plano" }, [
                _c("span", { staticClass: "strong" }, [_vm._v("Plano")]),
                _vm._v(" Kristal"),
                _c(
                  "span",
                  {
                    staticClass: "strong-2",
                    style: { color: _vm.planInfo.color },
                  },
                  [_vm._v(" " + _vm._s(_vm.planInfo.title) + " ")]
                ),
              ]),
              _c("div", { staticClass: "empresa" }, [
                _c("span", { staticClass: "strong" }, [
                  _vm._v("Kristal Connect"),
                ]),
              ]),
              _c("div", { staticClass: "valor" }, [
                _c("span", { staticClass: "valor-pagamento strong" }, [
                  _vm._v(" " + _vm._s(_vm.planInfo.preco)),
                ]),
                _c("span", { staticClass: "tipo-pagamento" }, [
                  _vm._v("Pagamento à vista - feito com cartão de crédito"),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "botoes" },
        [
          _c(
            "div",
            {
              staticClass: "btn continue-btn",
              on: { click: _vm.goToCarteira },
            },
            [_c("span", [_vm._v("Continuar com o plano")])]
          ),
          _c(
            "v-btn",
            {
              staticClass: "btn",
              attrs: { loading: _vm.loading, rounded: "", color: "primary" },
              on: { click: _vm.cancelarPlano },
            },
            [_vm._v("Cancelar plano")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }